<!--
 * @Description: 公海
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-04-23 11:31:33
 * @LastEditors: Seven
 * @LastEditTime: 2021-12-02 17:39:04
-->
<template>
  <div class="higsea-list">
    <div ref="contentTop">
      <el-row style="width: 94%; margin: 0 auto">
        <el-col>
          <el-button
            v-if="permUtil.WbCusotmer.higseaReceive()"
            :disabled="currList.length === 0"
            @click="reveice"
            style="margin: 10px 0 0 0"
            type="primary"
            class="hq-button"
            icon="el-icon-upload"
            >领取</el-button
          >
        </el-col>
        <el-col>
          <Search
            v-if="searchParamList.length > 0"
            :searchAllParam="searchAllParam"
            @search="search"
            @reset="reset"
            @searchMore="searchMore"
            @searchList="searchList"
            :searchParamList="searchParamList"
            :searchParamFromApi="searchParamFromApi"
            code="higsea"
          />
        </el-col>
      </el-row>
      <div class="line"></div>
    </div>
    <el-row style="width: 94%; margin: 0 auto">
      <el-col>
        <el-table
          highlight-current-row
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          :height="tableHeight"
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
          :row-style="{ height: '0px' }"
          :cell-style="{ padding: '0px' }"
          :header-cell-style="{ background: '#e5f2ff' }"
          @selection-change="selectionRow"
          @sort-change="sortChange"
        >
          <el-table-column
            type="selection"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="姓名"
            prop="name"
            min-width="120"
            
          >
            <template slot-scope="scope">
              <el-button type="text" @click="openDetail(scope.row)">
                {{ scope.row.name }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="手机"
            prop="phone"
            min-width="100"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="创建时间"
            prop="createTime"
            min-width="150"
            
            sortable="custom"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="意向状态"
            prop="followStatusName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="最后跟进时间"
            prop="followTime"
            min-width="150"
            sortable="custom"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="最后跟进人"
            prop="followUserName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="归属部门"
            prop="deptName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="QQ/微信验证状态"
            prop="verificationName"
            min-width="120"
            
          >
          </el-table-column>
        </el-table>
        <div class="page_test_bottom">
          <el-pagination
            background
            @size-change="sizeChange"
            @current-change="currentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="pageParam.pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageParam.pageSize"
            :total="total"
          />
        </div>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <Detail ref="drawer" :method="getHigseaList" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Search from "components/search";
import Detail from "./components/detail";
import { getUserCustomQuery, getRecruitSpinner, getRecruitSpinnernew } from "api/public";
import { getHigseaList, doHigseaReceive, getHigseaLabel } from "api/customer";
import { getUserList, getDeptUserTree,getTrackTypeList, getTrackTypeListTwo } from "api/public";

export default {
  name: "higsea",
  components: {
    Search,
    Detail,
  },
  data() {
    return {
      searchAllParam: [
        { name: "phone", label: "手机", type: "input", value: "" },
        { name: "name", label: "学员姓名", type: "input", value: "" },
        {
          name: "followTime",
          label: "最后跟进时间",
          type: "date",
          value: [],
          show: false,
          ref: "follow",
        },
        {
          name: "createTime",
          label: "创建时间",
          type: "date",
          value: [],
          show: false,
          ref: "createTime",
        },
        { name: "deptId", label: "归属部门", type: "tree", value: "", option: [] },
        {
          name: "followUserId",
          label: "最后跟进人",
          type: "select",
          value: "",
          option: [],
          text: "name",
          id: "id",
        },
        {
          name: "followStatus",
          label: "意向状态",
          type: "dictMult",
          code: "FOLLOW_STATUS_ACCOUNT",
          value: [],
        },
        { name: "weiXin", label: "微信", type: "input", value: "" },
        { name: "qq", label: "QQ", type: "input", value: "" },
        {
          name: "followLabels",
          label: "客户标签",
          type: "allSelect",
          value: [],
          option: [],
          text: "name",
          id: "id",
        },
        {
          name: "intention",
          label: "历史意向",
          type: "select",
          value: "",
          option: [
            { name: "是", id: 1 },
            { name: "否", id: 0 },
          ],
          text: "name",
          id: "id",
        },
        { name: "customerId", label: "客户编号", type: "input", value: "" },
        {
          name: "recruitChannels",
          label: "渠道名称",
          type: "allSelect",
          option: [],
          value: [],
          text: "name",
          id: "id"
        },
      ],
      searchParamList: [],
      defaultParamList: ["phone", "name", "createTime"],
      searchParamFromApi: [],
      tableData: [],
      listLoading: false,
      total: 0,
      pageParam: {
        pageNum: 1,
        pageSize: 20,
        sortItems: [
          {
            asc: false,
            column: "",
          },
        ],
      },
      // 列表
      currList: [],
      searchParam: {},
      // 页面是否有滚动条
      isScroll: true,
      topHeight: 0,
    };
  },
  computed: {
    ...mapGetters(["loginUser"]),
    tableHeight() {
      return window.outerHeight - this.topHeight - 110 + 'px'
    }
  },
  mounted() {
    this.getSearchList();
    this.getHigseaList();
    this.searchAllParam.map((item) => {
      if (item.name === "deptId") {
        getDeptUserTree().then((data) => item.option = data.result);
      } else if (item.name === "followUserId") {
        console.log('loginUser',this.loginUser);
        let params = {
          trackType: 0
        }
        getTrackTypeListTwo(params).then((data) => {
          console.log('data11',data);
          item.option = data.data.result
          console.log('item.option',item.option);
        });
      } else if (item.name === "followLabels") {
        getHigseaLabel().then((data) => (item.option = data.result));
      }else if (item.name === "recruitChannels") {
        getRecruitSpinner().then(data => {
          item.option = data.result
        })
      }
    });
    
  },
  methods: {
    // 更新自定义查询
    searchList() {
      this.getSearchList();
    },
    // 更多查询条件
    searchMore(value) {
      this.searchParamList = value
        ? this.searchAllParam
        : this.defaultParamList;
        this.$nextTick(() => {
          this.topHeight = this.$refs.contentTop.offsetHeight + 20
        })
    },
    // 获取自定义查询的默认查询字段
    async getSearchList() {
      const { code, result } = await getUserCustomQuery("higsea");
      if (code === 0) {
        this.defaultParamList = ["phone", "name", "createTime"]
        if (result && result.fieldData && JSON.parse(result.fieldData).length > 0) {
          this.searchParamFromApi = JSON.parse(result.fieldData);
          
          this.defaultParamList = []
          this.searchParamFromApi.map(() => {
            this.defaultParamList.push("")
          })
          let index
          this.searchAllParam.filter((item) => {
            index = this.searchParamFromApi.findIndex(i => i === item.name)
            this.defaultParamList[index] = item
          });

        } else {
          this.defaultParamList = this.searchAllParam.filter((item) =>
            this.defaultParamList.includes(item.name)
          );
        }
        this.searchParamList = this.defaultParamList;
        this.$nextTick(() => {
          this.topHeight = this.$refs.contentTop.offsetHeight + 46
        })
      }
    },
    // 领取
    reveice() {
      let phoneList = [];
      let params = [];
      this.currList.forEach((value) => {
        phoneList.push(value.phone);
        params.push({
          customerId: value.customerId,
          phone: value.phone,
          deptId: value.deptId,
        });
      });
      this.$hqMessageBox({
        message: `请确定是否将商机（${phoneList[0]}等${phoneList.length}条商机）领到领取客户？`,
      })
        .then(async () => {
          const { code } = await doHigseaReceive(params);
          if (code === 0) {
            // this.$message.success("领取成功");
            setTimeout(() => {
              this.getHigseaList();
            }, 1000)
          }
        })
        .catch(() => {
          console.log("取消");
        });
    },
    // 打开详情
    openDetail(row) {
      this.$refs.drawer.init(row)
    },
    // 获取列表数据
    async getHigseaList() {
      this.listLoading = true;
      const { code, result, msg } = await getHigseaList({...this.pageParam, ...this.searchParam});
      if (code === 0) {
        this.tableData = result.list;
        this.total = result.total;
        this.$nextTick(() => {
          this.isScroll = document.body.scrollHeight > window.innerHeight
        })
      } else {
        this.$message.error(msg)
      }
      this.listLoading = false;
    },
    // 多选事件--
    selectionRow(val) {
      this.currList = val;
    },

    // 改变分页条数
    sizeChange(val) {
      this.pageParam.pageSize = val;
      this.getHigseaList();
    },

    // 改变分页
    currentChange(val) {
      this.pageParam.pageNum = val;
      this.getHigseaList();
    },
    // 排序
    sortChange(val) {
      this.pageParam.sortItems[0].asc = !(val.order == "descending")
      this.pageParam.sortItems[0].column = val.prop;
      this.getHigseaList();
    },
    // 查询
    search() {
      this.searchParam = {};
      this.searchParamList.map((item) => {
        if (item.value  || ( Array.isArray(item.value)  && item.value.length)) {
          if (item.type === "date") {
            if (item.name === "createTime") {
              this.searchParam.beginCreateTime = item.value[0];
              this.searchParam.endCreateTime = item.value[1];
            } else if (item.name === "followTime") {
              this.searchParam.beginFollowTime = item.value[0];
              this.searchParam.endFollowTime = item.value[1];
            }
          } else {
            this.searchParam[item.name] = item.value;
          }
        }
      });
      this.getHigseaList();
    },
    // 重置
    reset() {
      this.searchParamList.map((item) => {
        if (item.value || item.value == 0) {
          if(item.name === "followTime" ||
          item.name === "createTime" || 
          item.name === "followStatus" ||
          item.name === "followLabels") {
            item.value = []
          } else {
            item.value = ""; 
          }
        }
      });
      this.pageParam = {
        pageNum: 1,
        pageSize: 20,
        sortItems: [
          {
            asc: false,
            column: "",
          },
        ],
      };
      this.search();
    },
  },
};
</script>
<style lang="scss" scoped>
.higsea-list {
  // padding-bottom: 40px;
  .search-btn {
    position: absolute;
  }

  .tool-btn {
    position: absolute;
    right: 20px;
    top: 0;
  }
}
</style>
